import { ArrowLeft, GoogleIcon, Outlook } from "../../../icons";

const LoginWithMagic = props => {
  const { loginLinkMSG, onBack } = props;

  return (
    <>
      <div className="userLogin-email custom-form">
        <>
          <div className="userLogin-email-msg">{loginLinkMSG}</div>
          <div className="userLogin-line"></div>
          <div className="userLogin-email-msg">Open this email in</div>

          <div className="userLogin-social mt-20">
            <a
              href="https://mail.google.com/"
              target="_blank"
              rel="noreferrer"
              className="userLogin-social-btn"
            >
              <GoogleIcon /> Gmail
            </a>
            <a
              href="https://outlook.live.com/mail/0/"
              target="_blank"
              rel="noreferrer"
              className="userLogin-social-btn mt-0"
            >
              <Outlook /> Outlook
            </a>
          </div>

          <div
            className="userLogin-email-link text-underline mt-20"
            onClick={onBack}
            role="link"
            tabIndex={-1}
          >
            Did not get the link?
          </div>
        </>
      </div>
      <div className="userLogin-email-footer">
        <button
          type="button"
          onClick={onBack}
          className="userLogin-social-btn userLogin-email-footer-col ml-auto mr-auto"
        >
          <ArrowLeft /> Go Back
        </button>
      </div>
    </>
  );
};
export default LoginWithMagic;
