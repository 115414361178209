export function UserIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3332 14.0001V12.6667C13.3332 11.9595 13.0523 11.2812 12.5522 10.7811C12.0521 10.281 11.3738 10.0001 10.6665 10.0001H5.33318C4.62593 10.0001 3.94765 10.281 3.44755 10.7811C2.94746 11.2812 2.6665 11.9595 2.6665 12.6667V14.0001"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00017 7.33335C9.47293 7.33335 10.6668 6.13944 10.6668 4.66667C10.6668 3.19391 9.47293 2 8.00017 2C6.52741 2 5.3335 3.19391 5.3335 4.66667C5.3335 6.13944 6.52741 7.33335 8.00017 7.33335Z"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default UserIcon;
