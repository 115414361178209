import axios from "axios";
import { GET_USER_AUTH_TOKEN } from "../helper/auth";
import { API_URL } from "../helper/constants";

/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */

export function apiCall(url, data, signal = null) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: GET_USER_AUTH_TOKEN()
  };
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + url, data, {
        headers: headers,
        signal: signal
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function apiCallMultipart(url, data, onUploadProgress) {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: GET_USER_AUTH_TOKEN()
  };

  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + url, data, {
        headers: headers,
        onUploadProgress
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
export async function fetchStream(url, data, signal = null) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: GET_USER_AUTH_TOKEN()
  };

  try {
    const response = await fetch(API_URL + url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
      signal: signal
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    // Return the reader and decoder to be processed in the component
    return { reader, decoder };
  } catch (error) {
    console.error("Request failed", error);
    throw error;
  }
}
