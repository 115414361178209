import { useRef, useState } from "react";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";
import { marked } from "marked";
import FullPageLoader from "../Common/FullPageLoader";
import aiLogo from "../../assets/images/6ixAI.png";
import ChatAction from "./ChatAction";
import AIHistoryShare from "../AIHistory/AIHistoryShare";
import { UserIcon } from "../../icons";

const AIChatContent = props => {
  const {
    chatcontent,
    chatResponseArea,
    handleScroll,
    userData,
    responsedata,
    selectedTopic,
    reading,
    setMessage,
    setRegenerateMessage,
    disableSearch
  } = props;
  const componentRef = useRef([]);
  const [enableShare, setEnableShare] = useState(false);
  return (
    <>
      <div
        className="ai6ix-page-chat-content-area-response"
        ref={chatResponseArea}
        onScroll={handleScroll}
      >
        {chatcontent.map((result, keys) => {
          return (
            <div
              key={"qe" + keys}
              ref={el => (componentRef.current[keys] = el)}
              className="ai6ix-page-chat-content-area-response-row"
            >
              <div className="ai6ix-page-chat-content-area-response-box-question ai6ix-page-chat-content-area-response-box">
                <div className="print-visible">Question:</div>
                <div className="ai6ix-page-chat-content-area-response-box-question-logo print-hidden">
                  {userData?.profileImage ? (
                    <img src={userData.profileImage} alt="" />
                  ) : (
                    <UserIcon />
                  )}
                </div>
                {result?.content}
              </div>
              <div
                className="ai6ix-page-chat-content-area-response-box-answer ai6ix-page-chat-content-area-response-box"
                key={"re" + keys}
              >
                <div className="ai6ix-page-chat-content-area-response-box-answer-logo print-hidden">
                  <img src={aiLogo} alt="" />
                </div>
                {responsedata[keys] ? (
                  <>
                    {responsedata[keys]?.map((res, rekey) => {
                      return (
                        <div
                          key={"res-" + rekey}
                          className="ai6ix-page-chat-content-area-response-box-answer-content"
                        >
                          <div className="print-visible">Answer:</div>

                          {selectedTopic?.searchType ? (
                            <div
                              className="ai6ix-page-chat-content-area-response-box-answer-content-row"
                              id={`sixai-chats-inner-response-${keys}-${rekey}`}
                            >
                              {parse(
                                selectedTopic?.searchType
                                  ? typeof res === "string"
                                    ? res
                                    : ""
                                  : marked(typeof res === "string" ? res : "")
                              )}
                            </div>
                          ) : (
                            <div
                              className="ai6ix-page-chat-content-area-response-box-answer-content-row"
                              id={`sixai-chats-inner-response-${keys}-${rekey}`}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  marked(typeof res === "string" ? res : "")
                                )
                              }}
                            />
                          )}

                          <div className="ai6ix-page-chat-content-area-response-box-answer-content-action">
                            <ChatAction
                              chatResponseArea={chatResponseArea}
                              reading={reading}
                              answer={res}
                              question={result?.content}
                              componentRef={componentRef}
                              currentChatIndex={keys}
                              questions={chatcontent}
                              answers={responsedata}
                              selectedTopic={selectedTopic}
                              setEnableShare={setEnableShare}
                              setMessage={setMessage}
                              setRegenerateMessage={setRegenerateMessage}
                              disableSearch={disableSearch}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <FullPageLoader normal={true} classes="ml-auto mr-auto" />
                )}
              </div>
            </div>
          );
        })}
      </div>
      {selectedTopic && enableShare && (
        <AIHistoryShare
          itemSelected={selectedTopic}
          onClose={() => {
            setEnableShare(false);
          }}
        />
      )}
    </>
  );
};

export default AIChatContent;
