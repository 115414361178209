export function ArrowLeft() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6673 8H3.33398"
        stroke="#C0C0C0"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00065 12.6668L3.33398 8.00016L8.00065 3.3335"
        stroke="#C0C0C0"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ArrowLeft;
