import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layout";
import LandingPage from "./pages/LandingPage";
import RefreshPage from "./pages/RefreshPage";
import { ToastContainer } from "react-toastify";
import HistoryPage from "./pages/HistoryPage";
import ChatPage from "./pages/ChatPage";

const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        closeOnClick={true}
        hideProgressBar={true}
        autoClose={3000}
        theme="dark"
        position="bottom-left"
      />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/history/:listType?" element={<HistoryPage />} />
          <Route path="/chat/:listId?" element={<ChatPage />} />
        </Route>
        <Route path="/R_C_P" element={<RefreshPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
