import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";

import { ShareIcon, TrashIcon, PencilIcon } from "../../icons";

const AIHistoryItem = props => {
  const { onAction, listItem, isShared } = props;
  return (
    <Link
      to={`/chat/${listItem?.id}`}
      className="ai6ix-page-history-content-row-box"
    >
      <div className="ai6ix-page-history-content-row-box-text">
        {listItem?.name}
      </div>
      <div
        className="ai6ix-page-history-content-row-box-action"
        onClick={e => e.preventDefault()}
        role="button"
        tabIndex={-1}
      >
        {isShared ? (
          <IconButton
            aria-label="Delete"
            className="ai6ix-page-history-content-row-box-action-btn"
            title="Delete"
            onClick={() => {
              onAction(listItem, "delete");
            }}
          >
            <TrashIcon />
          </IconButton>
        ) : (
          <>
            <IconButton
              aria-label="Share"
              className="ai6ix-page-history-content-row-box-action-btn"
              title="Share"
              onClick={() => {
                onAction(listItem, "share");
              }}
            >
              <ShareIcon />
            </IconButton>
            <IconButton
              aria-label="Edit"
              className="ai6ix-page-history-content-row-box-action-btn"
              title="Edit"
              onClick={() => {
                onAction(listItem, "edit");
              }}
            >
              <PencilIcon />
            </IconButton>
            <IconButton
              aria-label="Delete"
              className="ai6ix-page-history-content-row-box-action-btn"
              title="Delete"
              onClick={() => {
                onAction(listItem, "delete");
              }}
            >
              <TrashIcon />
            </IconButton>
          </>
        )}
      </div>
    </Link>
  );
};

export default AIHistoryItem;
