import aiLogo from "../../assets/images/6ixAI.png";

const AIChatLogo = () => {
  return (
    <div className="ai6ix-page-chat-content-logo">
      <img
        src={aiLogo}
        alt="logo"
        className="ai6ix-page-chat-content-logo-img"
      />
    </div>
  );
};

export default AIChatLogo;
