import AiFooterAccess from "./AiFooterAccess";
import AiFooterBottom from "./AiFooterBottom";
import AiFooterContent from "./AiFooterContent";
import AiFooterDivider from "./AiFooterDivider";
import AiFooterMenu from "./AiFooterMenu";
import AiFooterTop from "./AiFooterTop";
import "./style.scss";

const AiFooter = () => {
  return (
    <div className="ai6ix-layout-footer">
      <AiFooterTop />
      <AiFooterDivider />
      <AiFooterAccess />
      <AiFooterDivider />
      <AiFooterMenu />
      <AiFooterDivider />
      <AiFooterContent />
      <AiFooterBottom />
    </div>
  );
};

export default AiFooter;
