import { apiCall, apiCallMultipart } from "./servicesCall";

export const TOPIC_SERVICES = {
  getList(data) {
    return apiCall("/api/v1/verified/topics/get", data);
  },
  addAttachment(data) {
    return apiCallMultipart("/api/v1/verified/topics/attachment/add", data);
  },
  edit(data) {
    return apiCall("/api/v1/verified/topics/edit", data);
  },
  delete(data) {
    return apiCall("/api/v1/verified/topics/delete", data);
  },
  deleteAll(data) {
    return apiCall("/api/v1/verified/topics/clear", data);
  },
  getShareList(data) {
    return apiCall("/api/v1/verified/topics/getShared", data);
  },
  share(data) {
    return apiCall("/api/v1/verified/topics/share", data);
  },
  deleteShare(data) {
    return apiCall("/api/v1/verified/topics/deleteShared", data);
  },
  deleteShareAll(data) {
    return apiCall("/api/v1/verified/topics/clearShared", data);
  },
  getHistory(data) {
    return apiCall("/api/v1/verified/topics/getTopicHistory", data);
  }
};
