import PageHeader from "../components/PageHeader";
import AIHistory from "../components/AIHistory";

const HistoryPage = () => {
  return (
    <div className="ai6ix-page-history ai6ix-layout-page">
      <PageHeader />
      <div className="ai6ix-layout-page-content">
        <AIHistory />
      </div>
    </div>
  );
};

export default HistoryPage;
