import PageHeader from "../components/PageHeader";
import Landing from "../components/Landing";
import SEO from "../components/Common/SEO";
import AiFooter from "../components/AiFooter";

const LandingPage = () => {
  return (
    <div className="ai6ix-page-landing ai6ix-layout-page">
      <SEO />

      <PageHeader />
      <div className="ai6ix-layout-page-content">
        <Landing />
      </div>
      <AiFooter />
    </div>
  );
};

export default LandingPage;
