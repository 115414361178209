import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";

const ConfirmModal = props => {
  const { onCancel, onAccept, title, message } = props;
  return (
    <Dialog
      open={true}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ai6ix-dialog"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle
        className="text-center"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <DialogContent>
        <DialogContentText
          className="text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </DialogContent>
      <DialogActions className="ai6ix-dialog-action">
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCancel}
          className="ai6ix-btn ai6ix-btn-light"
          type="button"
        >
          No, thanks
        </Button>
        <Button
          variant="outlined"
          onClick={onAccept}
          color="primary"
          className="ai6ix-btn ai6ix-btn-primary"
          type="button"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmModal;
