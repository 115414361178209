import PageHeader from "../components/PageHeader";
import AIChat from "../components/AIChat";

const ChatPage = () => {
  return (
    <div className="ai6ix-page-chat ai6ix-layout-page">
      <PageHeader />
      <div className="ai6ix-layout-page-content ai6ix-layout-page-content-space-no ai6ix-page-chat-content ai6ix-layout-page-middle">
        <AIChat />
      </div>
    </div>
  );
};

export default ChatPage;
