import { apiCall, fetchStream } from "./servicesCall";

export const CHAT_SERVICES = {
  query(data, signal) {
    return fetchStream("/api/v1/chat/_a", data, signal);
  },
  update(data) {
    return apiCall("/api/v1/chat/_u", data);
  }
};
