export function Outlook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <g clipPath="url(#clip0_33_497)">
        <path
          d="M15.4999 2.95789H8.49999C8.224 2.95789 8 3.17081 8 3.43316C8 3.69551 8.224 3.90844 8.49999 3.90844H14.0429L10.971 6.1793L8.76799 4.84758L8.232 5.64985L10.732 7.16123C10.8129 7.21066 10.907 7.2354 11 7.2354C11.108 7.2354 11.2169 7.2021 11.307 7.13556L14.9999 4.40463V10.5623H8.49999C8.224 10.5623 8 10.7753 8 11.0376C8 11.3 8.224 11.5129 8.49999 11.5129H15.4999C15.7759 11.5129 15.9999 11.3 15.9999 11.0376V3.43316C15.9999 3.17081 15.7759 2.95789 15.4999 2.95789Z"
          fill="#C0C0C0"
        />
        <path
          d="M8.81891 0.217865C8.70486 0.127558 8.55189 0.0885895 8.40789 0.117106L0.407995 1.54293C0.170993 1.58476 0 1.78057 0 2.00965V12.4657C0 12.6939 0.170993 12.8907 0.407995 12.9325L8.40789 14.3583C8.43793 14.364 8.46887 14.3668 8.49989 14.3668C8.61589 14.3668 8.72886 14.3288 8.81891 14.2575C8.93393 14.1672 8.99988 14.0322 8.99988 13.8916V0.583823C8.99988 0.442195 8.93393 0.308163 8.81891 0.217865Z"
          fill="#C0C0C0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 7.71325C2 9.28543 3.12199 10.5649 4.49997 10.5649C5.87795 10.5649 6.99993 9.28543 6.99993 7.71325C6.99993 6.14102 5.87795 4.86157 4.49997 4.86157C3.12199 4.86157 2 6.14102 2 7.71325ZM3 7.7127C3 6.66421 3.673 5.81157 4.49998 5.81157C5.32697 5.81157 5.99996 6.66421 5.99996 7.7127C5.99996 8.7611 5.32697 9.61381 4.49998 9.61381C3.673 9.61381 3 8.7611 3 7.7127Z"
          fill="#2B2B2B"
        />
      </g>
      <defs>
        <clipPath id="clip0_33_497">
          <rect
            width="16"
            height="14.2589"
            fill="white"
            transform="translate(0 0.108765)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default Outlook();
