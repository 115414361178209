import React from "react";
import { Outlet } from "react-router-dom";
import { useGlobalValue } from "../GlobalContext";
import Header from "../components/Header";
import HeaderSidebar from "../components/Header/HeaderSidebar";

const Layout = () => {
  const { openSidebar } = useGlobalValue();
  return (
    <React.Fragment>
      <Header />
      {openSidebar && <HeaderSidebar />}
      <div className="ai6ix-layout-wrapper">
        <Outlet />
      </div>
    </React.Fragment>
  );
};

export default Layout;
