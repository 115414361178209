import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RefreshPage = () => {
  const navigate = useNavigate();
  const dataFeteched = useRef();
  const location = useLocation();
  useEffect(() => {
    if (dataFeteched?.current) return;
    dataFeteched.current = true;
    navigate(location?.state?.redirectOn || -1, {
      replace: location?.state?.replace || false
    });
  }, [navigate, location]);
  return <></>;
};

export default RefreshPage;
