import { Link } from "react-router-dom";

const AiFooterMenuCMS = () => {
  return (
    <div className="ai6ix-layout-footer-cmsmenu">
      <div className="ai6ix-layout-footer-cmsmenu-inner">
        <div className="ai6ix-layout-footer-cmsmenu-link">
          <Link
            className="ai6ix-layout-footer-cmsmenu-link-btn"
            to="https://6ix.com/disclaimer"
          >
            Disclaimer
          </Link>
        </div>
        <div className="ai6ix-layout-footer-cmsmenu-link">
          <Link
            className="ai6ix-layout-footer-cmsmenu-link-btn"
            to="https://6ix.com/privacy-policy"
          >
            Privacy Policy
          </Link>
        </div>
        <div className="ai6ix-layout-footer-cmsmenu-link">
          <Link
            className="ai6ix-layout-footer-cmsmenu-link-btn"
            to="https://6ix.com/terms"
          >
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AiFooterMenuCMS;
