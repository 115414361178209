const AiFooterDisclamer = () => {
  return (
    <div className="ai6ix-layout-footer-content-right-box">
      <div className="ai6ix-layout-footer-content-right-box-heading">
        DISCLAIMER OF LIABILITY
      </div>
      <div className="ai6ix-layout-footer-content-right-box-text ai6ix-layout-footer-content-right-box-text-sm">
        <div className="ai6ix-layout-footer-content-space">
          6ix Inc. (“6ix”) licenses software to publicly traded issuers of
          securities (“Issuers” and each an “Issuer”) which can be used by
          Issuers to communicate with current and prospective investors
          (“Investors” and each an “Investor”) on the online platform maintained
          by 6ix Inc. at www.6ix.com (the “Platform”). Each Issuer is solely
          responsible for all content relating to the Issuer on the 6ix
          Platform, including without limitation all investor summits presented
          by the Issuer and all summary information about the Issuer on the 6ix
          Platform.
        </div>
        <div className="ai6ix-layout-footer-content-space">
          All content provided on the 6ix Platform is for informational and
          educational purposes only and should not be construed as investment
          advice or an offer or solicitation in respect to any products or
          services. The content presented on the 6ix Platform should not be used
          as the basis for any investment decision, and does not purport to
          provide any legal, tax or accounting advice. There are inherent risks
          involved with investing in Issuers, as set out in the public
          disclosure record of each Issuer. Issuers are not responsible for
          revising or updating any information that they present on the 6ix
          Platform.
        </div>
        <div className="ai6ix-layout-footer-content-space">
          Investors should refer to the continuous disclosure documents filed by
          each Issuer under applicable securities laws, including risk factors
          and warnings regarding “forward looking information”. Issuers are
          solely responsible for compliance with applicable securities laws, and
          6ix makes no representations and provides no assurances to Investors
          regarding the accuracy or truthfulness of information presented by
          Issuers on the 6ix Platform or in their public disclosure records.
        </div>
        <div className="ai6ix-layout-footer-content-space">
          6ix is not licensed to trade, deal or advise in securities in any
          jurisdiction. 6ix does not recommend or endorse any Issuer on the 6ix
          Platform, nor does 6ix verify the accuracy of any information
          presented by Issuers to Investors on the 6ix Platform or in their
          public disclosure records. 6ix’s sole responsibility as the operator
          of the 6ix Platform is to provide software to Issuers and Investors
          through which Issuers and Investors communicate directly with each
          other without any intermediation or intervention by 6ix other than in
          respect of technical, logistical or advertising and marketing support.
          Issuers pay 6ix a licensing fee for use of the 6ix Platform, and 6ix
          may also receive payment from Issuers to pay for advertising on
          third-party websites. 6ix and its principals may make and hold
          investments in securities of Issuers on the 6ix Platform and 6ix may
          offer reduced rates for its software services to Issuers in which 6ix
          and/or its principals are invested.
        </div>
        <div className="ai6ix-layout-footer-content-space">
          ALL CONTENT ON THE PLATFORM IS PROVIDED BY THE ISSUERS “AS IS” AND “AS
          AVAILABLE”. 6IX DOES NOT GUARANTEE THE ACCURACY OF ITS CONTENT. BY
          USING THE PLATFORM, EACH INVESTOR AGREES TO ACCEPT ANY RISKS
          ASSOCIATED WITH THE USE OF THE PLATFORM AND ACKNOWLEDGES THAT 6IX IS
          NOT RESPONSIBLE FOR ANY CONTENT PRESENTED BY OR RELATING TO ISSUERS ON
          THE PLATFORM.
        </div>
        <div className="ai6ix-layout-footer-content-space">
          6IX DOES NOT WARRANT THAT THE PROVISION OF INFORMATION BY ISSUERS ON
          THE PLATFORM WILL BE ERROR-FREE, TIMELY, COMPLETE OR ACCURATE. USE OF
          THE PLATFORM AND RELIANCE THEREON IS AT INVESTOR’S SOLE RISK. 6IX WILL
          NOT BE IN ANY WAY BE LIABLE TO ANY INVESTOR OR ISSUER OR ANY OTHER
          ENTITY OR PERSON FOR ANY INACCURACIES, ERRORS, OMISSIONS, DELAYS,
          DAMAGES, CLAIMS, LIABILITIES OR LOSSES, REGARDLESS OF CAUSE, IN OR
          ARISING FROM THE USE OF THE PLATFORM.
        </div>
        IN NO EVENT WILL 6IX BE LIABLE FOR ANY DAMAGES, INCLUDING WITHOUT
        LIMITATION DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
        DAMAGES, LOSSES OR EXPENSES ARISING IN CONNECTION WITH THE USE OF THE
        PLATFORM BY INVESTORS AND/OR ISSUERS EVEN IF 6IX IS ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES, LOSSES OR EXPENSES. FURTHER, 6IX SHALL NOT
        BE LIABLE IN ANY MANNER FOR THE PRODUCT OR SERVICES OF ANYONE WHO
        REDISTRIBUTES THE INFORMATION PROVIDED ON THE PLATFORM, AND SUCH
        REDISTRIBUTION IS EXPRESSLY PROHIBITED.
      </div>
    </div>
  );
};

export default AiFooterDisclamer;
