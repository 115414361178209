export function CopyIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_514)">
        <path
          d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16797 12.5013H3.33464C2.89261 12.5013 2.46868 12.3257 2.15612 12.0131C1.84356 11.7006 1.66797 11.2767 1.66797 10.8346V3.33464C1.66797 2.89261 1.84356 2.46868 2.15612 2.15612C2.46868 1.84356 2.89261 1.66797 3.33464 1.66797H10.8346C11.2767 1.66797 11.7006 1.84356 12.0131 2.15612C12.3257 2.46868 12.5013 2.89261 12.5013 3.33464V4.16797"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_514">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CopyIcon;
