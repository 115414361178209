import AiFooterContentLeft from "./AiFooterContentLeft";
import AiFooterContentRight from "./AiFooterContentRight";

const AiFooterContent = () => {
  return (
    <div className="ai6ix-layout-footer-content">
      <AiFooterContentLeft />
      <AiFooterContentRight />
    </div>
  );
};

export default AiFooterContent;
