import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { SESSION_AUTH } from "../../helper/auth";
import FullPageLoader from "../Common/FullPageLoader";
import AIChatSearch from "./AIChatSearch";
import AIChatContent from "./AIChatContent";
import AIChatLogo from "./AIChatLogo";
import { TOPIC_SERVICES, CHAT_SERVICES } from "../../services";
import { toast } from "react-toastify";
import { useGlobalValue } from "../../GlobalContext";
import {
  GET_PUBLIC_SESSION,
  SET_PUBLIC_SESSION,
  REMOVE_PUBLIC_SESSION
} from "../../helper/constants";
import SEO from "../Common/SEO";

import "./style.scss";

let scrollAllow = true;
let messagesRes;

const AIChat = () => {
  const { listId } = useParams();
  const navigate = useNavigate();
  const { setLoginRequest, setLoginUserVerify } = useGlobalValue();
  const [userData, setUserData] = useState(null);
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [selectedTopic, setSelectedTopic] = useState("");
  const [loading, setLoading] = useState(false);
  const [reading, setReading] = useState(false);
  const [message, setMessage] = useState("");
  const [searchBy, setSearchBy] = useState("ai");
  const [regenerateMessage, setRegenerateMessage] = useState();
  const [regenratedresponse, setRegenratedresponse] = useState(false);
  const [hideicon, setHideicon] = useState(false);
  const [responseProcessLevel, setResponseProcessLevel] = useState(0);
  const [count, setCount] = useState(-1);
  const [chatcontent, setChatcontent] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const dataAiDraft = useRef(false);
  const [topicid, setTopicid] = useState("");
  const [responsedata, setResponsedata] = useState([]);
  const [topicLoader, setTopicLoader] = useState(listId ? true : false);
  const [controller, setController] = useState(null);
  const [leatestResponse, setLeatestResponse] = useState("");
  const [showStopbtn, setShowstopbtn] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [disableSearch, setDisableSearch] = useState(false);

  // refrence
  const chatResponseArea = useRef();

  //
  const location = useLocation();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const Q_AI = params.get("q_ai");

    if (Q_AI) {
      setMessage(Q_AI);
      setRegenerateMessage(true);
    }
  }, [location]);

  useEffect(() => {
    if (isUserSession) {
      if (!isUserSession?.isVerify) {
        setLoginUserVerify(true);
        return navigate("/");
      }
      setTopicid(listId || "");
      setDataFetched(false);

      return setUserData(isUserSession);
    } else if (listId) {
      return navigate("/chat");
    } else {
      return setUserData("");
    }
  }, [isUserSession, listId]);

  useEffect(() => {
    if (!userData || !topicid || dataFetched) return;
    setDataFetched(true);
    TOPIC_SERVICES.getHistory({
      userId: userData.userId,
      org: userData?.org?.id,
      id: topicid
    })
      .then(data => {
        if (data.code === 200) {
          setSelectedTopic(data?.data?.topic);
          setResponsedata(data?.data?.answer);
          setChatcontent(data?.data?.question);
          if (data?.data?.topic?.dataFrame?.length > 0 && message) {
            setRegenerateMessage(true);
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
          navigate("/chat");
        }
      })
      .catch(error => {
        toast(error.message);
        navigate("/chat");
      })
      .finally(() => {
        setTopicLoader(false);
      });
  }, [topicid, userData, dataFetched]);

  useEffect(() => {
    if (userData && !dataAiDraft?.current) {
      dataAiDraft.current = true;
      let aiDraft = GET_PUBLIC_SESSION("aidraft");
      aiDraft = aiDraft ? JSON.parse(aiDraft) : null;
      REMOVE_PUBLIC_SESSION("aidraft");
      if (
        aiDraft &&
        aiDraft?.content &&
        aiDraft?.query &&
        new Date().toDateString() === aiDraft?.time &&
        !listId
      ) {
        updateConversation(aiDraft?.content, aiDraft?.query);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, listId]);

  useEffect(() => {
    setDisableSearch(selectedTopic?.isShared);
  }, [selectedTopic]);

  const handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    if (message.trim().length !== 0 && !responseProcessLevel) {
      getallmessages();
      setCount(count + 1);
      setScroll(old => old + 1);
    }
  };

  useEffect(() => {
    if (regenerateMessage) {
      handleSubmit();
      setRegenerateMessage("");
    }
  }, [regenerateMessage]);

  const handleLogin = () => {
    setLoginRequest(true);
  };

  const getallmessages = (regenratemessage, conf) => {
    if (
      (message !== "" &&
        message.trim().length !== 0 &&
        message !== undefined) ||
      (regenratemessage !== "" && regenratemessage !== undefined)
    ) {
      if (conf) {
        setRegenratedresponse(true);
        setChatcontent(chatcontent);
      } else {
        setChatcontent([
          ...chatcontent,
          { role: "user", content: message ? message : regenratemessage }
        ]);
      }
    }
  };

  const updateConversation = async (chatcontent, messages) => {
    try {
      let postdata = {
        query: chatcontent,
        topicID: topicid,
        messages: messages,
        userId: userData?.userId,
        org: userData?.org?.id
      };

      let res = await CHAT_SERVICES.update(postdata);

      if (res.code === 200) {
        if (res?.data?.topicsNew) {
          navigate("/chat/" + res?.data?.topicID);
        }
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        toast(res?.errors);
      }
    } catch (err) {
      toast(err?.message);
    }
  };

  const onSelectDataFrame = async e => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setLoading(true);

    try {
      let res = await TOPIC_SERVICES.addAttachment({
        id: topicid,
        attachment: e.target.files[0],
        userId: userData?.userId,
        org: userData?.org?.id
      });

      if (res.code === 200) {
        if (res?.data?.topicsNew) {
          if (res?.data?.topic?.searchType === "CSV") {
            setMessage(
              `Provide a description of the columns, and data, and provide your best interpretation about what ${res?.data?.filename} file is about`
            );
          } else {
            setMessage(
              "Provide a brief summary of the content of the article, along with one unique insight based on the content"
            );
          }

          navigate("/chat/" + res?.data?.topic?.id);
        }
      }
      if (res.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        toast(res?.message);
      }
    } catch (err) {
      toast(err?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (scroll && chatResponseArea?.current) {
      chatResponseArea.current.scrollTo(
        0,
        (chatResponseArea?.current?.scrollTop || 0) + 5000
      );
    }
    return () => null;
  }, [scroll]);

  const handleScroll = () => {
    if (chatResponseArea.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatResponseArea.current;
      if (scrollTop + clientHeight + 50 >= scrollHeight) {
        scrollAllow = true;
      } else {
        scrollAllow = false;
      }
    }
  };

  const handleAbortClick = () => {
    setReading(false);
    if (controller) {
      controller.abort();
    }
  };

  const requestWithLatestMessage = async () => {
    if (message !== "" || regenratedresponse) {
      if (!userData) {
        let searchAccess = parseInt(Cookies.get("searchAccess")) || 0;
        searchAccess = searchAccess + 1;

        if (searchAccess > 5) {
          toast(
            "Unlock unlimited searches: Login to discover more with 6ix AI!"
          );
          setMessage("");
          handleLogin();
          return;
        }
        Cookies.set("searchAccess", searchAccess, { expires: 365 });
      }

      setHideicon(true);
      setMessage("");
      setResponseProcessLevel(1);
      setScroll(old => old + 1);
      try {
        const newController = new AbortController();
        setController(newController);
        let requestMessageList = chatcontent; //chatcontent; //[...messageList()]
        const postdata = {
          query: requestMessageList
        };

        if (selectedTopic && selectedTopic?.dataFrame?.length > 0) {
          postdata.dataFrame = [];
          for (let c = 0; c < selectedTopic?.dataFrame?.length; c++) {
            if (selectedTopic?.dataFrame?.[c]?.url)
              postdata.dataFrame.push(selectedTopic?.dataFrame?.[c]?.url);
          }
          postdata.searchType = selectedTopic?.searchType;
        } else {
          postdata.searchType = searchBy;
        }

        messagesRes = "";
        if (regenratedresponse) {
          let n = [...responsedata];
          n[n.length - 1] = [];
          setResponsedata(old => [...old, ...n]);
        } else {
          let n = [[]];
          setResponsedata(old => [...old, ...n]);
        }
        //setResponsedata(value);
        const signal = newController.signal;
        const { reader, decoder } = await CHAT_SERVICES.query(postdata, signal);

        let done = false;
        setReading(true);
        while (!done) {
          const { value, done: readerDone } = await reader.read();

          if (readerDone) {
            setRegenratedresponse(false);
            setReading(false);
            setHideicon(false);
            setShowstopbtn(false);
            setResponseProcessLevel(0);
            let lastsearchQuery = [...chatcontent].pop();
            if (userData) {
              if (messagesRes) {
                updateConversation(lastsearchQuery?.content, messagesRes);
              } else {
                setLeatestResponse("No Result Generated !");
              }
            } else if (lastsearchQuery?.content) {
              let today = new Date();
              SET_PUBLIC_SESSION(
                "aidraft",
                JSON.stringify({
                  content: lastsearchQuery?.content,
                  query: messagesRes,
                  time: today.toDateString()
                })
              );
            }
            return;
          } else {
            if (value) {
              try {
                let char = decoder.decode(value);
                if (char.indexOf("data: ") > -1) {
                  char = char.split("data: ");

                  for (let i = 0; i < char.length; i++) {
                    if (!char[i] || char[i].trim() === "[DONE]") {
                      continue;
                    }
                    let text;
                    try {
                      let json = JSON.parse(char[i]);
                      text = json?.choices?.[0]?.delta?.content || "";
                      if (json.choices?.[0].delta?.final_content) {
                        text = json.choices[0].delta?.final_content;
                        messagesRes = "";
                      }
                    } catch (err) {
                      //text = char[i];
                    }

                    if (text) {
                      messagesRes = messagesRes ? messagesRes + text : text;

                      if (regenratedresponse) {
                        setLeatestResponse(messagesRes);
                      } else {
                        setLeatestResponse(messagesRes);
                      }
                      setResponseProcessLevel(0);
                    }
                  }

                  if (scrollAllow) {
                    setScroll(old => old + 1);
                  }
                } else {
                  try {
                    let resData = JSON.parse(char);
                    if (resData?.code === 600) {
                      LOGOUT_USER_SESSION();
                    } else if (resData?.errors) {
                      toast(resData?.errors);
                    }
                  } catch (err) {
                    //console.log(err);
                  }
                }
              } catch (e) {
                //console.log(e);
              }
            }
          }
          setShowstopbtn(true);
          done = readerDone;
        }
      } catch (e) {
        setShowstopbtn(false);
        setController(null);
        return;
      }
    }
  };

  useEffect(() => {
    if (
      (message !== "" && message !== undefined && chatcontent?.length > 0) ||
      regenratedresponse
    ) {
      requestWithLatestMessage();
    }
  }, [chatcontent, regenratedresponse]);

  useEffect(() => {
    if (leatestResponse) {
      let old = [...responsedata];
      old[old.length - 1] = [leatestResponse];

      setResponsedata(old);
    }
  }, [leatestResponse]);
  return (
    <React.Fragment>
      <SEO
        title={
          (selectedTopic?.name ? selectedTopic?.name + " | " : "") +
          " Chat | 6ix Ai"
        }
        url={window.location.href}
      />
      {topicLoader || loading ? (
        <FullPageLoader normal={topicLoader} classes="mt-20" />
      ) : (
        <div className="ai6ix-page-chat-content-area">
          {chatcontent?.length > 0 ? (
            <AIChatContent
              chatcontent={chatcontent}
              chatResponseArea={chatResponseArea}
              handleScroll={handleScroll}
              userData={userData}
              responsedata={responsedata}
              selectedTopic={selectedTopic}
              reading={reading}
              setMessage={setMessage}
              setRegenerateMessage={setRegenerateMessage}
              disableSearch={disableSearch}
            />
          ) : (
            <AIChatLogo />
          )}
          {!disableSearch ? (
            <AIChatSearch
              selectedTopic={selectedTopic}
              userData={userData}
              onSelectDataFrame={onSelectDataFrame}
              handleLogin={handleLogin}
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              reading={reading}
              setResponseProcessLevel={setResponseProcessLevel}
              responseProcessLevel={responseProcessLevel}
              hideicon={hideicon}
              regenerateMessage={regenerateMessage}
              setMessage={setMessage}
              message={message}
              handleSubmit={handleSubmit}
              showStopbtn={showStopbtn}
              handleAbortClick={handleAbortClick}
              chatcontent={chatcontent}
            />
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default AIChat;
