export function PdfIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1450_24)">
        <path
          d="M27.8047 6.862L21.138 0.195312C21.013 0.0703125 20.8438 0 20.6666 0H6.66663C5.19594 0 4 1.19594 4 2.66669V29.3334C4 30.8041 5.19594 32 6.66669 32H25.3334C26.8041 32 28 30.8041 28 29.3333V7.33331C28 7.15625 27.9297 6.987 27.8047 6.862ZM21.3333 2.27606L25.7239 6.66669H22.6666C21.9316 6.66669 21.3333 6.06838 21.3333 5.33337V2.27606ZM26.6667 29.3333C26.6667 30.0683 26.0684 30.6666 25.3334 30.6666H6.66669C5.93169 30.6666 5.33337 30.0683 5.33337 29.3333V2.66669C5.33337 1.93169 5.93169 1.33337 6.66669 1.33337H20V5.33337C20 6.80406 21.1959 8 22.6667 8H26.6667V29.3333Z"
          fill="#E9E9E9"
          stroke="#E9E9E9"
          strokeWidth="0.5"
        />
        <path
          d="M19.3991 19.5723C18.7819 19.0866 18.1953 18.5873 17.8047 18.1966C17.2969 17.6888 16.8444 17.1967 16.4512 16.7279C17.0645 14.8327 17.3334 13.8555 17.3334 13.3347C17.3334 11.1218 16.5339 10.668 15.3334 10.668C14.4212 10.668 13.3334 11.1419 13.3334 13.3985C13.3334 14.3933 13.8783 15.601 14.9584 17.0046C14.6941 17.8112 14.3835 18.7416 14.0346 19.7904C13.8666 20.2936 13.6843 20.7598 13.4916 21.1908C13.3347 21.2605 13.1823 21.3314 13.0352 21.405C12.5052 21.67 12.002 21.9082 11.5352 22.1296C9.40625 23.1374 8 23.8041 8 25.1205C8 26.0762 9.03844 26.668 10 26.668C11.2396 26.668 13.1113 25.0123 14.4785 22.2233C15.8978 21.6634 17.6621 21.2486 19.0547 20.9889C20.1706 21.847 21.403 22.668 22 22.668C23.653 22.668 24 21.7122 24 20.9108C24 19.3346 22.1992 19.3346 21.3333 19.3346C21.0644 19.3346 20.3431 19.4141 19.3991 19.5723ZM10 25.3346C9.61913 25.3346 9.36131 25.155 9.33331 25.1205C9.33331 24.6478 10.7428 23.9798 12.1061 23.334C12.1927 23.293 12.2806 23.252 12.3698 23.2096C11.3685 24.6615 10.3783 25.3346 10 25.3346ZM14.6667 13.3985C14.6667 12.0013 15.1003 12.0013 15.3334 12.0013C15.8047 12.0013 16.0001 12.0013 16.0001 13.3347C16.0001 13.6159 15.8126 14.319 15.4694 15.4167C14.9459 14.6107 14.6667 13.92 14.6667 13.3985ZM15.1777 20.5638C15.2194 20.448 15.2598 20.3308 15.2989 20.2123C15.5462 19.4701 15.7689 18.8034 15.9675 18.2031C16.2442 18.5078 16.5424 18.8197 16.8621 19.1393C16.9871 19.2643 17.2969 19.5456 17.7097 19.8978C16.888 20.0768 16.0137 20.2988 15.1777 20.5638ZM22.6667 20.9108C22.6667 21.2103 22.6667 21.3346 22.0482 21.3386C21.8666 21.2995 21.4466 21.0521 20.9284 20.6993C21.1165 20.6785 21.2552 20.668 21.3333 20.668C22.3184 20.668 22.5977 20.7643 22.6667 20.9108Z"
          fill="#E9E9E9"
        />
      </g>
      <defs>
        <clipPath id="clip0_1450_24">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PdfIcon;
