import { Link } from "react-router-dom";

const HeaderTop = () => {
  return (
    <div className="ai6ix-layout-header-top">
      <div className="ai6ix-layout-header-top-text">
        Interested in hosting an event?
      </div>
      <Link
        to="https://6ix.com/create"
        className="ai6ix-layout-header-top-link"
      >
        Get started for FREE
      </Link>
    </div>
  );
};

export default HeaderTop;
