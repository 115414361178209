import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { useGlobalValue } from "../../GlobalContext";
import { SESSION_AUTH } from "../../helper/auth";
import { TxtIcon, PdfIcon, CopyIcon, ShareIcon, ReloadIcon } from "../../icons";
import { toast } from "react-toastify";
import { exportChatResponse, exportWholeChat } from "../../helper/constants";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js/dist/html2pdf.min.js";

const ChatAction = props => {
  const {
    reading,
    question,
    answer,
    componentRef,
    currentChatIndex,
    questions,
    answers,
    selectedTopic,
    setEnableShare,
    setMessage,
    setRegenerateMessage,
    chatResponseArea,
    disableSearch
  } = props;
  const { isUserSession } = SESSION_AUTH();
  const { setLoginRequest } = useGlobalValue();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const copyAction = () => {
    handleClose();
    navigator.clipboard.writeText(answer);
    toast("Copied!");
  };
  const txtAction = () => {
    handleClose();
    exportChatResponse({
      question: question,
      answer: answer
    });
  };
  const txtFullAction = () => {
    handleClose();
    exportWholeChat({
      questions: questions,
      answers: answers
    });
  };

  const pdfAction = useReactToPrint({
    content: () => componentRef.current[currentChatIndex],
    copyStyles: true,
    removeAfterPrint: true,
    bodyClass: "print",
    print: async printIframe => {
      const document = printIframe.contentDocument;
      var opt = {
        margin: 10,
        filename: `response.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1.1 },
        jsPDF: {
          unit: "mm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          floatPrecision: 12
        },
        enableLinks: false,
        pagebreak: { mode: ["avoid-all"] }
      };
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        html2pdf().set(opt).from(html).save();
      }
    }
  });
  const pdfFullAction = useReactToPrint({
    content: () => chatResponseArea.current,
    copyStyles: true,
    removeAfterPrint: true,
    bodyClass: "print",
    print: async printIframe => {
      const document = printIframe.contentDocument;
      var opt = {
        margin: 10,
        filename: `response.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1.1 },
        jsPDF: {
          unit: "mm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          floatPrecision: 12
        },
        enableLinks: false,
        pagebreak: { mode: ["avoid-all"] }
      };
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        html2pdf().set(opt).from(html).save();
      }
    }
  });
  const regenerateAction = () => {
    handleClose();
    setRegenerateMessage(question);
    setMessage(question);
  };
  const shareAction = () => {
    handleClose();
    setEnableShare(true);
  };

  return isUserSession ? (
    <>
      <IconButton
        aria-label="more"
        id="ai-chat-action"
        aria-controls={open ? "ai-chat-action-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="ai6ix-page-chat-content-area-response-box-answer-content-action-btn"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="ai6ix-page-chat-content-area-response-box-answer-content-action-menu"
        id="ai-chat-action-menu"
        MenuListProps={{
          "aria-labelledby": "ai-chat-action"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              //  maxHeight: ITEM_HEIGHT * 4.5
              //   width: "20ch"
            }
          }
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem
          onClick={copyAction}
          className="ai6ix-page-chat-content-area-response-box-answer-content-action-menu-list"
        >
          <CopyIcon /> Copy response
        </MenuItem>
        <MenuItem
          onClick={txtAction}
          className="ai6ix-page-chat-content-area-response-box-answer-content-action-menu-list"
        >
          <TxtIcon />
          Download response (TXT)
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            pdfAction();
          }}
          className="ai6ix-page-chat-content-area-response-box-answer-content-action-menu-list"
        >
          <PdfIcon />
          Download response (PDF)
        </MenuItem>
        <MenuItem
          onClick={txtFullAction}
          className="ai6ix-page-chat-content-area-response-box-answer-content-action-menu-list"
        >
          <TxtIcon /> Download complete conversation (TXT)
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            pdfFullAction();
          }}
          className="ai6ix-page-chat-content-area-response-box-answer-content-action-menu-list"
        >
          <PdfIcon /> Download complete conversation (PDF)
        </MenuItem>
        {!reading && !disableSearch && (
          <MenuItem
            onClick={regenerateAction}
            className="ai6ix-page-chat-content-area-response-box-answer-content-action-menu-list"
          >
            <ReloadIcon /> Regenerate response
          </MenuItem>
        )}
        {selectedTopic && !disableSearch && (
          <MenuItem
            onClick={shareAction}
            className="ai6ix-page-chat-content-area-response-box-answer-content-action-menu-list"
          >
            <ShareIcon /> Share with friends
          </MenuItem>
        )}
      </Menu>
    </>
  ) : (
    <IconButton onClick={() => setLoginRequest(true)}>
      <MoreVertIcon />
    </IconButton>
  );
};

export default ChatAction;
