import { isMobile } from "react-device-detect";
import AiFooterMenuCMS from "./AiFooterMenuCMS";

const AiFooterBottom = () => {
  return (
    <div className="ai6ix-layout-footer-bottom">
      <div className="ai6ix-layout-footer-bottom-text">
        believe in capitalism again
      </div>
      {isMobile ? <AiFooterMenuCMS /> : null}
    </div>
  );
};

export default AiFooterBottom;
