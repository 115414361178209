export function Plus(props) {
  return props?.circle ? (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.73"
        y="1.23"
        width="16.54"
        height="16.54"
        rx="8.27"
        stroke="#C0C0C0"
        strokeWidth="1.46"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0013 9.49955C15.0013 9.91459 14.6649 10.251 14.2498 10.251L3.74984 10.251C3.3348 10.251 2.99834 9.91459 2.99834 9.49955C2.99834 9.0845 3.3348 8.74805 3.74984 8.74805L14.2498 8.74805C14.6649 8.74805 15.0013 9.0845 15.0013 9.49955Z"
        fill="#C0C0C0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00021 3.49902C9.41525 3.49902 9.75171 3.83548 9.75171 4.25052L9.75171 14.7505C9.75171 15.1656 9.41525 15.502 9.00021 15.502C8.58517 15.502 8.24871 15.1656 8.24871 14.7505L8.24871 4.25052C8.24871 3.83548 8.58517 3.49902 9.00021 3.49902Z"
        fill="#C0C0C0"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.002 11.9997C20.002 12.5531 19.5533 13.0017 19 13.0017L4.99995 13.0017C4.44656 13.0017 3.99795 12.5531 3.99795 11.9997C3.99795 11.4463 4.44656 10.9977 4.99995 10.9977L19 10.9977C19.5533 10.9977 20.002 11.4463 20.002 11.9997Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.99817C12.5533 3.99817 13.002 4.44678 13.002 5.00017L13.002 19.0002C13.002 19.5536 12.5533 20.0022 12 20.0022C11.4466 20.0022 10.998 19.5536 10.998 19.0002L10.998 5.00017C10.998 4.44678 11.4466 3.99817 12 3.99817Z"
        fill="white"
      />
    </svg>
  );
}
export default Plus;
