import { isMobile } from "react-device-detect";
import AiFooterContentAttract from "./AiFooterContentAttract";
import AiFooterContentExpertise from "./AiFooterContentExpertise";
import AiFooterContentInvest from "./AiFooterContentInvest";
import AiFooterMenuCMS from "./AiFooterMenuCMS";

const AiFooterContentLeft = () => {
  return (
    <div className="ai6ix-layout-footer-content-left">
      <AiFooterContentInvest />
      <AiFooterContentAttract />
      <AiFooterContentExpertise />
      {!isMobile ? <AiFooterMenuCMS /> : null}
    </div>
  );
};

export default AiFooterContentLeft;
