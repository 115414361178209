export function LogoIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.2881 38.4194H37.7075V14.8065C37.7075 14.0939 37.1298 13.5161 36.4171 13.5161H26.7397V10.2903C26.7397 9.57776 26.1621 9.00002 25.4494 9.00002H16.4978C15.7852 9.00002 15.2075 9.57776 15.2075 10.2903V13.5161H14.1591C13.4465 13.5161 12.8688 14.0939 12.8688 14.8065V38.4194H10.2881C9.57553 38.4194 8.99779 38.9971 8.99779 39.7097C8.99779 40.4223 9.57553 41 10.2881 41H40.2881C41.0008 41 41.5784 40.4223 41.5784 39.7097C41.5784 38.9971 41.0008 38.4194 40.2881 38.4194ZM17.7881 11.5807H24.1591V13.5161H17.7881V11.5807ZM22.7075 38.4194V32.9355H27.8688V38.4194H22.7075ZM30.4494 38.4194V32.9355H31.0139C31.7266 32.9355 32.3042 32.3578 32.3042 31.6452C32.3042 30.9326 31.7266 30.3549 31.0139 30.3549C26.5257 30.3549 23.7194 30.3549 19.5623 30.3549C18.8497 30.3549 18.272 30.9326 18.272 31.6452C18.272 32.3578 18.8497 32.9355 19.5623 32.9355H20.1268V38.4194H15.4494V16.0968H35.1268V38.4194H30.4494ZM23.4333 21.5645C23.4333 22.2771 22.8555 22.8549 22.143 22.8549H20.6913C19.9788 22.8549 19.401 22.2771 19.401 21.5645C19.401 20.852 19.9788 20.2742 20.6913 20.2742H22.143C22.8555 20.2742 23.4333 20.852 23.4333 21.5645ZM31.0946 21.5645C31.0946 22.2771 30.5169 22.8549 29.8042 22.8549H28.3526C27.64 22.8549 27.0623 22.2771 27.0623 21.5645C27.0623 20.852 27.64 20.2742 28.3526 20.2742H29.8042C30.5169 20.2742 31.0946 20.852 31.0946 21.5645ZM23.4333 26.4032C23.4333 27.1158 22.8555 27.6936 22.143 27.6936H20.6913C19.9788 27.6936 19.401 27.1158 19.401 26.4032C19.401 25.6907 19.9788 25.1129 20.6913 25.1129H22.143C22.8555 25.1129 23.4333 25.6907 23.4333 26.4032ZM31.0946 26.4032C31.0946 27.1158 30.5169 27.6936 29.8042 27.6936H28.3526C27.64 27.6936 27.0623 27.1158 27.0623 26.4032C27.0623 25.6907 27.64 25.1129 28.3526 25.1129H29.8042C30.5169 25.1129 31.0946 25.6907 31.0946 26.4032Z"
        fill="#E9E9E9"
      />
    </svg>
  );
}
export default LogoIcon;
