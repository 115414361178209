import React, { useState } from "react";
import { Button } from "@mui/material";
import { TrashIcon } from "../../icons";
import FullPageLoader from "../Common/FullPageLoader";
import { toast } from "react-toastify";
import { SESSION_AUTH } from "../../helper/auth";
import { TOPIC_SERVICES } from "../../services";
import ConfirmModal from "../Common/ConfirmModal";

const AIHistoryClear = props => {
  const { type, onUpdate } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const [requestClear, setRequestClear] = useState(false);

  const onClearAccept = async () => {
    try {
      let param = {
        userId: isUserSession.userId,
        org: isUserSession?.org?.id
      };
      setLoading(true);
      if (type === "query") {
        const data = await TOPIC_SERVICES.deleteAll(param);
        if (data.code === 200) {
          onUpdate();
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        }
        if (data.message) {
          toast(data.message);
        }
      } else if (type === "shared") {
        const data = await TOPIC_SERVICES.deleteShareAll(param);
        if (data.code === 200) {
          onUpdate();
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        }
        if (data.message) {
          toast(data.message);
        }
      }
    } catch (error) {
      toast(error.message);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      {loading && <FullPageLoader />}
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setRequestClear(true)}
        className="ai6ix-btn ai6ix-btn-light"
        type="button"
        startIcon={<TrashIcon />}
        sx={{ textTransform: "none" }}
      >
        Clear conversations
      </Button>
      {requestClear && (
        <ConfirmModal
          title={`<small class="text-capitalize">${type}</small><br/>  All History`}
          message="Are you sure for delete?"
          onCancel={() => {
            setRequestClear(false);
          }}
          onAccept={onClearAccept}
        />
      )}
    </React.Fragment>
  );
};

export default AIHistoryClear;
