import { AttachnentIcon, SendIcon } from "../../icons";
import { TextareaAutosize, Button } from "@mui/material";
import { Stop as StopIcon } from "@mui/icons-material";

const AIChatSearch = props => {
  const {
    chatcontent,
    selectedTopic,
    userData,
    onSelectDataFrame,
    handleLogin,
    searchBy,
    setSearchBy,
    reading,
    setResponseProcessLevel,
    responseProcessLevel,
    hideicon,
    regenerateMessage,
    setMessage,
    message,
    handleSubmit,
    handleAbortClick,
    showStopbtn
  } = props;
  return (
    <div className="ai6ix-page-chat-content-searcharea">
      {chatcontent?.length ? (
        <div className="ai6ix-page-chat-content-searcharea-top">
          {responseProcessLevel === 1 ? null : (
            <>
              {!reading && !userData ? (
                <div className="ai6ix-page-chat-content-searcharea-top-login">
                  <p>Login to copy, share and use other features of 6ix AI</p>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleLogin}
                    className="ai6ix-btn ai6ix-btn-small ai6ix-btn-primary text-capitalize"
                  >
                    Login/Register
                  </Button>
                </div>
              ) : showStopbtn ? (
                <Button
                  onClick={handleAbortClick}
                  size="small"
                  variant="outlined"
                  className="ai6ix-btn ai6ix-btn-small ai6ix-btn-primary text-capitalize"
                >
                  <StopIcon />
                  Stop generating
                </Button>
              ) : null}
            </>
          )}
        </div>
      ) : null}

      <div className="ai6ix-page-chat-content-searcharea-box">
        {userData ? (
          <label
            title={`Add ${
              selectedTopic?.searchType
                ? selectedTopic?.searchType === "CSV"
                  ? "CSV"
                  : "DOC, DOCX, PDF"
                : "CSV, DOC, DOCX, PDF"
            }`}
            className="ai6ix-page-chat-content-searcharea-box-attachment"
          >
            <AttachnentIcon />

            <input
              type="file"
              className="ai6ix-page-chat-content-searcharea-box-attachment-input"
              accept={
                selectedTopic?.searchType
                  ? selectedTopic?.searchType === "CSV"
                    ? ".CSV"
                    : ".DOC,.DOCX,.PDF"
                  : ".CSV,.DOC,.DOCX,.PDF"
              }
              onChange={onSelectDataFrame}
            />
          </label>
        ) : (
          <div
            title="Add CSV, DOC, DOCX, PDF"
            onClick={handleLogin}
            role="button"
            tabIndex={-1}
            className="ai6ix-page-chat-content-searcharea-box-attachment"
          >
            <AttachnentIcon />
          </div>
        )}
        {!selectedTopic?.searchType ? (
          <select
            className="ai6ix-page-chat-content-searcharea-box-input-select"
            defaultValue={searchBy}
            onChange={e => setSearchBy(e?.target?.value)}
          >
            <option value="" disabled>
              Search By
            </option>
            <option value="ai">AI</option>
            <option value="internet">Internet</option>
          </select>
        ) : null}
        <TextareaAutosize
          tabIndex={-1}
          maxRows={10}
          className={`ai6ix-page-chat-content-searcharea-box-input ${
            !selectedTopic?.searchType
              ? "ai6ix-page-chat-content-searcharea-box-input-withSelect"
              : ""
          }`}
          placeholder="Enter your request..."
          disabled={reading}
          value={message}
          onChange={e => setMessage(e.target.value)}
          onKeyDown={e => e.key === "Enter" && !e.shiftKey && handleSubmit(e)}
        />
        {regenerateMessage ||
          (reading && (
            <div className="ai6ix-page-chat-content-searcharea-box-creating">
              <div className="ai6ix-page-chat-content-searcharea-box-creating-msg">
                AI is thinking...
              </div>
            </div>
          ))}

        {hideicon && responseProcessLevel > 0 ? (
          <div className="ai6ix-page-chat-content-searcharea-box-creating">
            <div className="ai6ix-page-chat-content-searcharea-box-creating-msg">
              AI is thinking...
            </div>
            {responseProcessLevel === 2 ? (
              <Button
                size="small"
                variant="outlined"
                className="ai6ix-btn ai6ix-btn-small ai6ix-btn-primary text-capitalize ml-20"
                onClick={() => {
                  setResponseProcessLevel(4);
                }}
              >
                STOP
              </Button>
            ) : null}
          </div>
        ) : (
          <div
            onClick={handleSubmit}
            role="button"
            tabIndex={-1}
            className="ai6ix-page-chat-content-searcharea-box-sendimg"
          >
            <SendIcon />
          </div>
        )}
      </div>

      {selectedTopic?.dataFrame ? (
        <div className="ai6ix-page-chat-content-searcharea-bottom">
          {selectedTopic?.dataFrame?.length}{" "}
          {selectedTopic?.searchType || "File"} Added
        </div>
      ) : null}
    </div>
  );
};

export default AIChatSearch;
