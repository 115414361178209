import React from "react";
import ReactDOM from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { GlobalProvider } from "./GlobalContext";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PostHogProvider } from "posthog-js/react";

import "./assets/css/base.scss";
import "./index.css";
import "./assets/css/style.scss";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  palette: {
    mode: "dark",

    primary: {
      main: "#e3c050"
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById("ai6ix-directory"));

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        session_recording: {
          maskAllInputs: false,
          recordCrossOriginIframes: true
        },
        enable_heatmaps: true
      }}
    >
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <GlobalProvider>
            <App />
          </GlobalProvider>
        </ThemeProvider>
      </HelmetProvider>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
