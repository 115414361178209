import { Link } from "react-router-dom";

const AiFooterMenu = () => {
  return (
    <div className="ai6ix-layout-footer-menu">
      <Link className="ai6ix-layout-footer-menu-link" to="/">
        Home
      </Link>
      <Link
        className="ai6ix-layout-footer-menu-link"
        to="https://6ix.com/about"
      >
        About
      </Link>
      <Link
        className="ai6ix-layout-footer-menu-link"
        to="https://6ix.com/careers"
      >
        Careers
      </Link>
      <Link
        className="ai6ix-layout-footer-menu-link"
        to="https://6ix.com/security"
      >
        Security
      </Link>
    </div>
  );
};

export default AiFooterMenu;
