export function ReloadIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_1393)">
        <path d="M1.16675 2.66669V6.66669H5.16675" fill="#C0C0C0" />
        <path
          d="M1.16675 2.66669V6.66669H5.16675"
          stroke="#fff"
          strokeWidth="1.48444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.84008 10C3.27234 11.2269 4.09164 12.2801 5.17452 13.0009C6.2574 13.7217 7.5452 14.0711 8.84389 13.9963C10.1426 13.9216 11.3818 13.4268 12.3748 12.5865C13.3679 11.7462 14.0609 10.606 14.3496 9.33758C14.6382 8.06917 14.5068 6.74131 13.9752 5.55407C13.4435 4.36684 12.5404 3.38454 11.402 2.75518C10.2635 2.12583 8.95135 1.88352 7.6632 2.06475C6.37505 2.24599 5.18069 2.84095 4.26008 3.76001L1.16675 6.66667"
          stroke="#C0C0C0"
          strokeWidth="1.48444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1393">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReloadIcon;
