import AiFooterAbout6ix from "./AiFooterAbout6ix";
import AiFooterDisclamer from "./AiFooterDisclamer";

const AiFooterContentRight = () => {
  return (
    <div className="ai6ix-layout-footer-content-right">
      <AiFooterAbout6ix />
      <AiFooterDisclamer />
    </div>
  );
};

export default AiFooterContentRight;
