import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl
} from "@mui/material";
import TagsInput from "react-tagsinput";
import FullPageLoader from "../Common/FullPageLoader";
import { toast } from "react-toastify";
import { SESSION_AUTH } from "../../helper/auth";
import { TOPIC_SERVICES } from "../../services";
import { isValidEmail } from "../../helper/constants";
import "react-tagsinput/react-tagsinput.css";

const AIHistoryShare = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { onClose, itemSelected } = props;
  const [loading, setLoading] = useState(false);

  const [emails, setEmails] = useState([]);

  const onSave = async () => {
    try {
      if (!emails?.length) {
        return toast("Enter friends email");
      }
      let param = {
        emails: emails,
        userId: isUserSession.userId,
        org: isUserSession?.org?.id,
        id: itemSelected?.id
      };

      setLoading(true);
      const data = await TOPIC_SERVICES.share(param);
      if (data.code === 200) {
        onClose();
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      }
      if (data.message) {
        toast(data.message);
      }
    } catch (error) {
      toast(error.message);
    }
    setLoading(false);
  };

  const addEmails = tags => {
    let unique = [...new Set(tags)];
    let emf = unique.filter(e => {
      return isValidEmail(e);
    });

    setEmails(emf);
  };

  return (
    <React.Fragment>
      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ai6ix-dialog "
        maxWidth="sm"
        fullWidth={true}
      >
        {loading && <FullPageLoader fixed={true} />}

        <DialogTitle className="text-center">
          Share with friends
          <br />
          <small>{itemSelected?.name}</small>
        </DialogTitle>
        <DialogContent>
          <FormControl className="custom-form-group">
            <label
              htmlFor="share-emails"
              className="custom-form-label big-label"
            >
              Enter one or more emails
            </label>

            <div className="custom-form-tags">
              <TagsInput
                id="share-emails"
                addKeys={[188, 9, 13, 49, 32]}
                value={emails}
                onChange={e => {
                  addEmails(e);
                }}
                inputProps={{ placeholder: "Enter email and press Enter" }}
              />
            </div>
          </FormControl>
          <br />
          <br />
          <br />
        </DialogContent>
        <DialogActions className="ai6ix-dialog-action">
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            className="ai6ix-btn ai6ix-btn-light"
            type="button"
          >
            Cancel
          </Button>

          <Button
            onClick={onSave}
            variant="outlined"
            color="primary"
            className="ai6ix-btn ai6ix-btn-primary"
            type="button"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default AIHistoryShare;
