import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const LandingIntro = () => {
  return (
    <section className="ai6ix-page-landing-section ai6ix-page-landing-intro">
      <div className="ai6ix-layout-page-middle">
        <div className="ai6ix-page-landing-intro-content">
          <h1 className="ai6ix-page-landing-intro-content-heading">
            Do your <span className="text-primary">press releases</span> <br />
            write themselves?
          </h1>
          <div className="ai6ix-page-landing-intro-content-text">
            Effortlessly generate professional press releases <br /> with
            cutting-edge AI technology.
          </div>
          <div className="ai6ix-page-landing-intro-content-action">
            <Button
              component={Link}
              to="/chat"
              color="primary"
              variant="outlined"
              type="button"
              className="ai6ix-btn ai6ix-btn-primary"
            >
              CHAT WITH 6ix AI
            </Button>
          </div>
        </div>
        <div className="ai6ix-page-landing-intro-video">
          <iframe
            src="https://videos.6ix.com/embed/661964bda988b9ebbedf2ec0"
            title="6ix Videos"
            frameBorder={0}
            autoPlay
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default LandingIntro;
