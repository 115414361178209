export function TrashIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.7002 4.50049H4.1002H15.3002" fill="#8C8E95" />
      <path
        d="M2.7002 4.50049H4.1002H15.3002"
        stroke="#8C8E95"
        strokeWidth="1.77769"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.19717 4.49846V3.09859C6.19717 2.72733 6.34467 2.37127 6.60722 2.10874C6.86977 1.84622 7.22586 1.69873 7.59717 1.69873H10.3972C10.7685 1.69873 11.1246 1.84622 11.3871 2.10874C11.6497 2.37127 11.7972 2.72733 11.7972 3.09859V4.49846M13.8972 4.49846V14.2975C13.8972 14.6688 13.7497 15.0248 13.4871 15.2874C13.2246 15.5499 12.8685 15.6974 12.4972 15.6974H5.49717C5.12586 15.6974 4.76977 15.5499 4.50722 15.2874C4.24467 15.0248 4.09717 14.6688 4.09717 14.2975V4.49846H13.8972Z"
        stroke="#8C8E95"
        strokeWidth="1.77769"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default TrashIcon();
