import { useEffect, useState } from "react";
import FullPageLoader from "../../Common/FullPageLoader";
import { FormControl } from "@mui/material";
import { toast } from "react-toastify";
import {
  auth,
  // signInWithApple,
  // signInWithGoogle,
  signInWithUserEmailLink
} from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../helper/auth";
//import { AppleIcon, GoogleIcon } from "../../../icons";
import { isValidEmail } from "../../../helper/constants";

const Login = props => {
  const { isUserSession } = SESSION_AUTH();
  const { handleLogin, email, setEmail, setLoginLinkMSG } = props;
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (isUserSession) {
      if (handleLogin) handleLogin();
    }
  }, [isUserSession, handleLogin]);

  const loginEmail = () => {
    if (email && isValidEmail(email)) {
      setLoader(true);
      signInWithUserEmailLink(email)
        .then(data => {
          if (data) {
            setLoginLinkMSG(data);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      toast("Enter your email address");
    }
  };

  // const loginWithGoogle = async () => {
  //   setLoader(true);
  //   const data = await signInWithGoogle();
  //   if (data?.code === 200 && data?.data) {
  //     SET_USER_SESSION(data?.data);
  //     if (handleLogin) handleLogin();
  //   }

  //   setLoader(false);
  // };
  // const loginWithApple = async () => {
  //   setLoader(true);
  //   const data = await signInWithApple();
  //   if (data?.code === 200 && data?.data) {
  //     SET_USER_SESSION(data?.data);
  //     if (handleLogin) handleLogin();
  //   }
  //   setLoader(false);
  // };

  useEffect(() => {
    if (error) {
      toast(error);
    }
  }, [error]);
  return (
    <>
      {(loading || loader) && <FullPageLoader fixed={true} />}
      <div className="userLogin-email custom-form">
        <FormControl className="custom-form-group">
          <label className="custom-form-label" htmlFor="login-email">
            Email
          </label>
          <input
            id="login-email"
            placeholder="your@email.com"
            className="custom-form-input"
            type="email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            name="email"
          />
        </FormControl>
        <button
          type="button"
          className="userLogin-email-btn mt-20"
          onClick={loginEmail}
          disabled={loader || loading}
        >
          Continue
        </button>

        {/* <div className="userLogin-or">
          <span className="userLogin-or-text">
            <span className="userLogin-or-text-inner">OR LOGIN WITH</span>
          </span>
        </div> 
        <div className="userLogin-social mt-20">
          <button
            onClick={loginWithGoogle}
            type="button"
            className="userLogin-social-btn"
          >
            <GoogleIcon /> Google
          </button>
          <button
            onClick={loginWithApple}
            type="button"
            className="userLogin-social-btn mt-0"
          >
            <AppleIcon /> Apple
          </button>
        </div>*/}
      </div>
    </>
  );
};
export default Login;
