export function MenuIcon(props) {
  return props?.close ? (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="#131313" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#E3C050" />
      <path
        d="M14.9141 14.9129L25.0964 25.0952"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.9141 25.0941L25.0964 14.9118"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 20H26.75"
        stroke="#C0C0C0"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 15.5H26.75"
        stroke="#C0C0C0"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 24.5H26.75"
        stroke="#C0C0C0"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default MenuIcon;
