import { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [loggedinNotified, setLoggedinNotified] = useState("");
  const [isUserReinitialize, setIsUserReinitialize] = useState("");
  const [loginUserVerify, setLoginUserVerify] = useState(false);
  const [loginRequest, setLoginRequest] = useState(false);

  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        loggedinNotified,
        setLoggedinNotified,
        isUserReinitialize,
        setIsUserReinitialize,
        openSidebar,
        setOpenSidebar,
        loginUserVerify,
        setLoginUserVerify,
        loginRequest,
        setLoginRequest
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalValue = () => useContext(GlobalContext);
