import { useEffect, useState } from "react";
import { Cross } from "../../../icons";
import Login from "./Login";
import LoginWithMagic from "./LoginWithMagic";
import { SESSION_AUTH } from "../../../helper/auth";
import "./Login.scss";
const LoginPopup = props => {
  const { isUserSession } = SESSION_AUTH();
  const { handleLogin, dropOut, cancel } = props;
  const [email, setEmail] = useState("");
  const [loginLinkMSG, setLoginLinkMSG] = useState("");

  useEffect(() => {
    if (isUserSession) {
      handleLogin();
    }
  }, [isUserSession, handleLogin]);

  return (
    <div
      className="ai6ix-popup ai6ix-popup-xs userLogin"
      onClick={() => {
        if (dropOut) {
          handleLogin();
        }
      }}
      role="link"
      tabIndex={-1}
    >
      <div
        className="ai6ix-popup-inner"
        onClick={e => {
          e.stopPropagation();
        }}
        role="link"
        tabIndex={-1}
      >
        <>
          <div className="ai6ix-popup-header">
            <div className="ai6ix-popup-header-heading">
              {loginLinkMSG ? <>Continue with Email </> : <>Enter your email</>}
            </div>
          </div>
          {cancel && (
            <div
              className="ai6ix-popup-close"
              onClick={handleLogin}
              role="button"
              tabIndex={-1}
            >
              <Cross />
            </div>
          )}
          <div className="ai6ix-popup-content">
            {loginLinkMSG ? (
              <LoginWithMagic
                loginLinkMSG={loginLinkMSG}
                onBack={() => {
                  setLoginLinkMSG("");
                }}
              />
            ) : (
              <Login
                handleLogin={handleLogin}
                setEmail={setEmail}
                email={email}
                setLoginLinkMSG={setLoginLinkMSG}
              />
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default LoginPopup;
