import { Link } from "react-router-dom";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import logo from "../../assets/images/6ixlogo.png";

const AiFooterTop = () => {
  return (
    <div className="ai6ix-layout-footer-top">
      <Link className="ai6ix-layout-footer-top-logo" title="6ix Ai" to="/">
        <img className="ai6ix-layout-header-logo-img" src={logo} alt="logo" />
      </Link>
      <div className="ai6ix-layout-footer-top-social">
        <div className="ai6ix-layout-footer-top-social-text">Follow us on</div>
        <Link
          to="https://www.linkedin.com/company/6ix"
          className="ai6ix-layout-footer-top-social-link"
          target="_blank"
          title="Linkedin"
        >
          <LinkedIn />
        </Link>
        <Link
          to="https://www.facebook.com/6ixdotcom"
          className="ai6ix-layout-footer-top-social-link"
          target="_blank"
          title="Facebook"
        >
          <Facebook />
        </Link>
        <Link
          to="https://twitter.com/6ix"
          className="ai6ix-layout-footer-top-social-link"
          target="_blank"
          title="Twitter"
        >
          <Twitter />
        </Link>
        <Link
          to="https://www.instagram.com/6ixdotcom/"
          className="ai6ix-layout-footer-top-social-link"
          target="_blank"
          title="Instagram"
        >
          <Instagram />
        </Link>
      </div>
    </div>
  );
};

export default AiFooterTop;
