import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { SESSION_AUTH } from "../../helper/auth";
import FullPageLoader from "../Common/FullPageLoader";
import AIHistoryItem from "./AIHistoryItem";
import { TOPIC_SERVICES } from "../../services";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmModal from "../Common/ConfirmModal";
import AIHistoryEdit from "./AIHistoryEdit";
import AIHistoryShare from "./AIHistoryShare";
import AIHistoryClear from "./AIHistoryClear";
import SEO from "../Common/SEO";
import { useGlobalValue } from "../../GlobalContext";
import "./style.scss";

const AIHistory = () => {
  const { listType } = useParams();
  const [userData, setUserData] = useState(null);
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { setLoginUserVerify } = useGlobalValue();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listingLoading, setListingLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState({ page: 1, limit: 50 });
  const [dataFetched, setDataFetched] = useState(false);
  const [itemSelected, setItemSelected] = useState("");
  const [itemActionType, setItemActionType] = useState("");

  const historyTabs = {
    query: "My Conversations",
    shared: "Shared with me"
  };
  const [listData, setListData] = useState([]);
  const [toggleState, setToggleState] = useState(
    historyTabs[listType] ? listType : "query"
  );

  useEffect(() => {
    if (isUserSession) {
      if (!isUserSession?.isVerify) {
        setLoginUserVerify(true);
        return navigate("/");
      }
      setUserData(isUserSession);
    } else {
      setUserData("");
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);

    try {
      setListingLoading(true);
      if (filter?.page === 1) {
        setListData([]);
      }
      let parma = {
        userId: userData.userId,
        org: userData?.org?.id,
        page: filter?.page,
        limit: filter?.limit
      };
      if (toggleState === "query") {
        TOPIC_SERVICES.getList(parma)
          .then(data => {
            if (data.code === 200) {
              if (filter?.page === 1) {
                setListData(data.data);
              } else {
                setListData(old => [...old, ...data.data]);
              }

              setHasMore(data?.data?.length >= parma?.limit);
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            } else {
              toast(data.message);
            }
          })
          .catch(error => {
            toast(error.message);
          })
          .finally(() => {
            setListingLoading(false);
          });
      } else if (toggleState === "shared") {
        TOPIC_SERVICES.getShareList(parma)
          .then(data => {
            if (data.code === 200) {
              if (filter?.page === 1) {
                setListData(data.data);
              } else {
                setListData(old => [...old, ...data.data]);
              }

              setHasMore(data?.data?.length >= parma?.limit);
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            } else {
              toast(data.message);
            }
          })
          .catch(error => {
            toast(error.message);
          })
          .finally(() => {
            setListingLoading(false);
          });
      }
    } catch (error) {
      toast(error.message);
    }

    return () => null;
  }, [userData, dataFetched, filter]);

  useEffect(() => {
    setDataFetched(false);
    return () => null;
  }, [filter]);

  const changeTabs = item => {
    if (item !== toggleState && !listingLoading) {
      setToggleState(item);
      setHasMore(true);
      resetFilter();
    }
  };

  const resetFilter = () => {
    setFilter(old => ({
      ...old,
      page: 1,
      last: new Date()
    }));
  };

  const loadMoreContent = () => {
    setFilter(old => ({
      ...old,
      page: old.page + 1
    }));
  };

  const deleteRequestAccept = () => {
    setItemActionType("");
    setLoading(true);
    if (toggleState === "query") {
      TOPIC_SERVICES.delete({
        userId: userData.userId,
        org: userData?.org?.id,
        id: itemSelected?.id
      })
        .then(data => {
          if (data.code === 200) {
            resetFilter();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            toast(data.message);
          }
        })
        .catch(error => {
          toast(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (toggleState === "shared") {
      TOPIC_SERVICES.deleteShare({
        userId: userData.userId,
        org: userData?.org?.id,
        id: itemSelected?.id
      })
        .then(data => {
          if (data.code === 200) {
            resetFilter();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            toast(data.message);
          }
        })
        .catch(error => {
          toast(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const onActionitem = (item, type) => {
    setItemSelected(item);
    setItemActionType(type);
  };
  return (
    <React.Fragment>
      <SEO
        title={(historyTabs?.[toggleState] || "") + " History | 6ix Ai"}
        url={window.location.href}
      />
      {loading && <FullPageLoader />}
      <div className="ai6ix-layout-page-middle">
        <div className="ai6ix-layout-page-top">
          <ul className="ai6ix-tabs ai6ix-page-history-tabs">
            {Object.keys(historyTabs).map((item, key) => (
              <Link
                to={`/history/${item}`}
                key={key}
                className={`ai6ix-tabs-item ${
                  item === toggleState ? "active" : ""
                }`}
                onClick={e => {
                  if (listingLoading) {
                    return e.preventDefault();
                  }
                  changeTabs(item);
                }}
              >
                {historyTabs[item]}
              </Link>
            ))}
          </ul>
          {listData?.length > 0 && (
            <AIHistoryClear type={toggleState} onUpdate={resetFilter} />
          )}
        </div>
        <div className="ai6ix-page-history-content">
          <InfiniteScroll
            className="ai6ix-page-history-content-row"
            dataLength={listData.length}
            next={loadMoreContent}
            hasMore={hasMore}
            loader={<FullPageLoader classes="m-t20" normal={true} />}
            endMessage={
              listData?.length === 0 && !listingLoading ? (
                <div className="ai6ix-page-history-content-empty">
                  No {listType} history avaible
                </div>
              ) : null
            }
          >
            {listData.map((listItem, key) => {
              return (
                <AIHistoryItem
                  listItem={listItem}
                  key={key}
                  onAction={onActionitem}
                  isShared={toggleState === "shared"}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      </div>

      {itemSelected && itemActionType === "delete" && (
        <ConfirmModal
          title={`<small>${historyTabs[toggleState]}</small><br/> ${itemSelected?.name}`}
          message="Are you sure for delete?"
          onCancel={() => {
            setItemActionType("");
            setItemSelected("");
          }}
          onAccept={deleteRequestAccept}
        />
      )}
      {itemSelected && itemActionType === "edit" && (
        <AIHistoryEdit
          itemSelected={itemSelected}
          onClose={() => {
            setItemActionType("");
            setItemSelected("");
          }}
          onUpdate={() => {
            resetFilter();
          }}
        />
      )}
      {itemSelected && itemActionType === "share" && (
        <AIHistoryShare
          itemSelected={itemSelected}
          onClose={() => {
            setItemActionType("");
            setItemSelected("");
          }}
        />
      )}
    </React.Fragment>
  );
};

export default AIHistory;
