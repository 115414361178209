import { useEffect, useState } from "react";
import { auth, signInWithUserEmailLink } from "../../../firebase";
import { useGlobalValue } from "../../../GlobalContext";
import { Cross } from "../../../icons";
import FullPageLoader from "../../Common/FullPageLoader";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../helper/auth";
import LoginWithMagic from "./LoginWithMagic";

const LoginVerifyPopup = props => {
  const { isUserSession } = SESSION_AUTH();
  const { setLoginUserVerify } = useGlobalValue();
  const { cancel } = props;
  const [email, setEmail] = useState("");
  const [, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const [loginLinkMSG, setLoginLinkMSG] = useState("");

  useEffect(() => {
    if ((isUserSession && isUserSession?.isVerify) || !isUserSession) {
      setLoginUserVerify(false);
    }
    if (isUserSession) {
      setEmail(isUserSession?.email);
    } else {
      setEmail("");
    }
  }, [isUserSession, setLoginUserVerify]);

  const loginWIthEmail = async () => {
    if (email) {
      setLoader(true);
      const data = await signInWithUserEmailLink(email);
      if (data) {
        setLoginLinkMSG(data);
      }
      setLoader(false);
    } else {
      toast("Enter your email address");
    }
  };

  return (
    <div
      className="ai6ix-popup ai6ix-popup-xs userLogin"
      onClick={() => {
        if (cancel) {
          setLoginUserVerify(false);
        }
      }}
      role="link"
      tabIndex={-1}
    >
      <div
        className="ai6ix-popup-inner"
        onClick={e => {
          e.stopPropagation();
        }}
        role="link"
        tabIndex={-1}
      >
        {(loading || loader) && <FullPageLoader />}
        <>
          <div className="ai6ix-popup-header">
            <div className="ai6ix-popup-header-heading">Verify Account</div>
            {!loginLinkMSG && (
              <div className="ai6ix-popup-header-desc">
                Welcome to the restricted area of the 6ix Events platform! To
                ensure the security of your account:
              </div>
            )}
          </div>
          {cancel && (
            <div
              className="ai6ix-popup-close"
              onClick={() => {
                setLoginUserVerify(false);
              }}
              role="link"
              tabIndex={-1}
            >
              <Cross />
            </div>
          )}
          <div className="ai6ix-popup-content">
            {loginLinkMSG ? (
              <LoginWithMagic
                loginLinkMSG={loginLinkMSG}
                onBack={() => {
                  setLoginLinkMSG("");
                }}
              />
            ) : (
              <div className="userLogin-social">
                <button
                  type="button"
                  className="userLogin-social-btn mt-20 mb-20"
                  onClick={loginWIthEmail}
                >
                  Verify with Email Magic Link
                </button>
              </div>
            )}
          </div>
        </>
      </div>
    </div>
  );
};
export default LoginVerifyPopup;
