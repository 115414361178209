import { CircularProgress } from "@mui/material";

const FullPageLoader = props => {
  return (
    <div
      className={`ai6ix-loader ${!props?.normal ? "ai6ix-loader-fixed" : ""}  ${props?.dark ? "ai6ix-loader-dark" : ""} ${props?.classes || ""}`}
    >
      <CircularProgress size={90} thickness={3.8} color="inherit" />
    </div>
  );
};

export default FullPageLoader;
