import { Link } from "react-router-dom";

const AiFooterContentInvest = () => {
  return (
    <div className="ai6ix-layout-footer-content-left-row">
      <div className="ai6ix-layout-footer-content-left-row-content">
        <div className="ai6ix-layout-footer-content-left-row-content-heading">
          Research Companies
        </div>
        <div className="ai6ix-layout-footer-content-left-row-content-text">
          <div className="ai6ix-layout-footer-content-space">
            Tools to help you invest better.
          </div>
          <div className="ai6ix-layout-footer-content-space">
            <ul>
              <li>Discover and access diverse global investment events</li>
              <li>
                Engage directly with company management through video-first
                events
              </li>
              <li>Access detailed, trustworthy information about events</li>
              <li>Learn from other investors and analysts</li>
            </ul>
          </div>
          <div className="ai6ix-layout-footer-content-space">
            Trusted by 100,000+ retail, accredited & institutional investors.
          </div>
        </div>
      </div>
      <div className="ai6ix-layout-footer-content-left-row-menu">
        <div className="ai6ix-layout-footer-content-left-row-menu-box">
          <div className="ai6ix-layout-footer-content-left-row-menu-box-heading">
            DISCOVER
          </div>

          <Link
            to="https://6ix.com/explore"
            className="ai6ix-layout-footer-content-left-row-menu-box-link"
          >
            Explore the Marketplace
          </Link>

          <Link
            to="https://ai.6ix.com"
            className="ai6ix-layout-footer-content-left-row-menu-box-link"
          >
            Chat with 6ix AI
          </Link>
        </div>

        <div className="ai6ix-layout-footer-content-left-row-menu-box">
          <div className="ai6ix-layout-footer-content-left-row-menu-box-heading">
            ENGAGE
          </div>
          <Link
            to="https://6ix.com/request-meeting"
            className="ai6ix-layout-footer-content-left-row-menu-box-link"
          >
            Book 1:1 Meetings
          </Link>
        </div>

        <div className="ai6ix-layout-footer-content-left-row-menu-box">
          <div className="ai6ix-layout-footer-content-left-row-menu-box-heading">
            TRANSACT
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiFooterContentInvest;
