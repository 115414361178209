import { Link } from "react-router-dom";

const AiFooterContentAttract = () => {
  return (
    <div className="ai6ix-layout-footer-content-left-row">
      <div className="ai6ix-layout-footer-content-left-row-content">
        <div className="ai6ix-layout-footer-content-left-row-content-heading">
          ATTRACT CAPITAL
        </div>
        <div className="ai6ix-layout-footer-content-left-row-content-text">
          <div className="ai6ix-layout-footer-content-space">
            Tools to help you attract capital.
          </div>
          <div className="ai6ix-layout-footer-content-space">
            <ul>
              <li>
                Efficiently raise capital and build a global investor base
              </li>
              <li>Reach a wider pool of potential investors</li>
              <li>Tell your story effectively to the right audience</li>
              <li>Build and maintain relationships with investors</li>
              <li>Access capital more quickly and at lower cost</li>
              <li>Gain valuable market intelligence and feedback</li>
            </ul>
          </div>
          <div className="ai6ix-layout-footer-content-space">
            Trusted by 400+ public and private companies.
          </div>
        </div>
      </div>
      <div className="ai6ix-layout-footer-content-left-row-menu">
        <div className="ai6ix-layout-footer-content-left-row-menu-box">
          <div className="ai6ix-layout-footer-content-left-row-menu-box-heading">
            Reach investors
          </div>

          <Link
            to="https://leads.6ix.com"
            className="ai6ix-layout-footer-content-left-row-menu-box-link"
          >
            Get Leads
          </Link>
        </div>

        <div className="ai6ix-layout-footer-content-left-row-menu-box">
          <div className="ai6ix-layout-footer-content-left-row-menu-box-heading">
            Engage investors
          </div>

          <Link
            to="https://6ix.com/create"
            className="ai6ix-layout-footer-content-left-row-menu-box-link"
          >
            Host Events
          </Link>
        </div>

        <div className="ai6ix-layout-footer-content-left-row-menu-box">
          <div className="ai6ix-layout-footer-content-left-row-menu-box-heading">
            Convert Investors
          </div>

          <Link
            to="https://meetings.6ix.com"
            className="ai6ix-layout-footer-content-left-row-menu-box-link"
          >
            Book Meetings
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AiFooterContentAttract;
