import CryptoJS from "crypto-js";
const ENCRYPT_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const API_URL = process.env.REACT_APP_ENV
  ? "https://aibackendapi.6ix.com"
  : "http://localhost:3001";

const ENCRYPT_DATA = data => {
  const encrypted = CryptoJS.AES.encrypt(data, ENCRYPT_SECRET_KEY).toString();
  return encrypted;
};
const DECRYPT_DATA = data => {
  try {
    const decrypted = CryptoJS.AES.decrypt(data, ENCRYPT_SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return decrypted;
  } catch (error) {
    return;
  }
};

export const SET_PUBLIC_SESSION = (name, value) => {
  if (value && name) {
    let data = ENCRYPT_DATA(value);
    localStorage.setItem(name, data);
  }
};

export const GET_PUBLIC_SESSION = name => {
  if (name && localStorage.getItem(name)) {
    try {
      let data = DECRYPT_DATA(localStorage.getItem(name));
      return data;
    } catch (err) {
      return "";
    }
  }
  return;
};
export const REMOVE_PUBLIC_SESSION = name => {
  localStorage.removeItem(name);
  return;
};

export const isValidEmail = email => {
  const emailValidRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(emailValidRegex)) {
    return true;
  }
  return false;
};

export const exportChatResponse = text => {
  const fileData = `Question: ${text.question}\nAnswer:\n ${text.answer}`;
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "response.txt";
  link.href = url;
  link.click();
};

export const exportWholeChat = chat => {
  const fileData = chat.questions.map(
    (question, index) =>
      `Question: ${question.content}\nAnswer:\n ${chat.answers[index]}\n`
  );
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "response.txt";
  link.href = url;
  link.click();
};
