import { Link } from "react-router-dom";

const AiFooterContentExpertise = () => {
  return (
    <div className="ai6ix-layout-footer-content-left-row">
      <div className="ai6ix-layout-footer-content-left-row-content">
        <div className="ai6ix-layout-footer-content-left-row-content-heading">
          SHARE EXPERTISE
        </div>
        <div className="ai6ix-layout-footer-content-left-row-content-text">
          <div className="ai6ix-layout-footer-content-space">
            Tools to help you share expertise.
          </div>
          <div className="ai6ix-layout-footer-content-space">
            <ul>
              <li>
                Monetize your knowledge and build your personal brand on a
                global scale
              </li>
              <li>Share insights with a broader audience</li>
              <li>Build credibility and authority in your field</li>
              <li>Create new revenue streams from your expertise</li>
              <li>Access comprehensive data for analysis</li>
              <li>Engage directly with companies and investors</li>
            </ul>
          </div>
          <div className="ai6ix-layout-footer-content-space">
            Trusted by 100+ industry experts, newsletter writers and equity
            analysts.
          </div>
        </div>
      </div>
      <div className="ai6ix-layout-footer-content-left-row-menu">
        <div className="ai6ix-layout-footer-content-left-row-menu-box">
          <div className="ai6ix-layout-footer-content-left-row-menu-box-heading">
            Amplify
          </div>
          <Link
            to="https://6ix.com/broadcast"
            className="ai6ix-layout-footer-content-left-row-menu-box-link"
          >
            Broadcast Interviews
          </Link>
        </div>

        <div className="ai6ix-layout-footer-content-left-row-menu-box">
          <div className="ai6ix-layout-footer-content-left-row-menu-box-heading">
            Network
          </div>
        </div>

        <div className="ai6ix-layout-footer-content-left-row-menu-box">
          <div className="ai6ix-layout-footer-content-left-row-menu-box-heading">
            Monetize
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiFooterContentExpertise;
