export function TxtIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6654 1.66797H4.9987C4.55667 1.66797 4.13275 1.84356 3.82019 2.15612C3.50763 2.46868 3.33203 2.89261 3.33203 3.33464V16.668C3.33203 17.11 3.50763 17.5339 3.82019 17.8465C4.13275 18.159 4.55667 18.3346 4.9987 18.3346H14.9987C15.4407 18.3346 15.8646 18.159 16.1772 17.8465C16.4898 17.5339 16.6654 17.11 16.6654 16.668V6.66797L11.6654 1.66797Z"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.668 1.66797V6.66797H16.668"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3346 10.832H6.66797"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3346 14.168H6.66797"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33463 7.5H7.5013H6.66797"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TxtIcon;
