import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl
} from "@mui/material";

import FullPageLoader from "../Common/FullPageLoader";
import { toast } from "react-toastify";
import { SESSION_AUTH } from "../../helper/auth";
import { TOPIC_SERVICES } from "../../services";

const AIHistoryEdit = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { onClose, itemSelected, onUpdate } = props;
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(itemSelected?.name || "");

  const onSave = async () => {
    try {
      let param = {
        name: name?.trim(),
        userId: isUserSession.userId,
        org: isUserSession?.org?.id,
        id: itemSelected?.id
      };
      if (!param?.name) {
        return toast("Enter history name");
      }
      setLoading(true);
      const data = await TOPIC_SERVICES.edit(param);
      if (data.code === 200) {
        onClose();
        onUpdate();
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      }
      if (data.message) {
        toast(data.message);
      }
    } catch (error) {
      toast(error.message);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ai6ix-dialog "
        maxWidth="sm"
        fullWidth={true}
      >
        {loading && <FullPageLoader fixed={true} />}

        <DialogTitle className="text-center">
          Edit <br />
          <small>{itemSelected?.name}</small>
        </DialogTitle>
        <DialogContent>
          <FormControl className="custom-form-group">
            <label htmlFor="name" className="custom-form-label big-label">
              Name
            </label>
            <input
              id="name"
              name="name"
              placeholder="Enter history name"
              className="custom-form-input"
              value={name}
              onChange={e => setName(e.target.value)}
              autoComplete="none"
              maxLength={30}
            />
          </FormControl>
          <br />
          <br />
          <br />
        </DialogContent>
        <DialogActions className="ai6ix-dialog-action">
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            className="ai6ix-btn ai6ix-btn-light"
            type="button"
          >
            Cancel
          </Button>

          <Button
            onClick={onSave}
            variant="outlined"
            color="primary"
            className="ai6ix-btn ai6ix-btn-primary"
            type="button"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default AIHistoryEdit;
