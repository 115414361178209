import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Add as AddIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { SESSION_AUTH } from "../../helper/auth";
import "./style.scss";

const CommonMenu = () => {
  const { isUserSession } = SESSION_AUTH();
  const navigate = useNavigate();
  const location = useLocation();

  const reLoadChat = () => {
    if (location?.pathname.includes("/chat")) {
      setTimeout(function () {
        navigate("/R_C_P", { state: { replace: true } });
      }, 10);
    }
  };

  return (
    <React.Fragment>
      <div className="ai6ix-layout-page-header-left">
        <div className="ai6ix-layout-page-header-menu">
          <NavLink to="/" className="ai6ix-layout-page-header-menu-link">
            Overview
          </NavLink>
          {isUserSession ? (
            <>
              <NavLink
                to="/history"
                className="ai6ix-layout-page-header-menu-link"
              >
                History
              </NavLink>
              <NavLink
                to="/chat"
                className="ai6ix-layout-page-header-menu-link"
                onClick={reLoadChat}
              >
                Chat
              </NavLink>
            </>
          ) : null}
        </div>
      </div>
      {!isMobile ? (
        <div className="ai6ix-layout-page-header-right">
          <Button
            component={Link}
            to="/chat"
            color="primary"
            variant="outlined"
            className="ai6ix-btn ai6ix-btn-primary"
            startIcon={<AddIcon />}
            onClick={reLoadChat}
          >
            New Chat
          </Button>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const PageHeader = () => {
  return (
    <div className="ai6ix-layout-page-header">
      <CommonMenu />
    </div>
  );
};

export default PageHeader;
