export function PencilIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.232 3.22662C12.3989 3.05972 12.597 2.92733 12.8151 2.83701C13.0331 2.74668 13.2668 2.7002 13.5029 2.7002C13.7389 2.7002 13.9726 2.74668 14.1907 2.83701C14.4087 2.92733 14.6069 3.05972 14.7738 3.22662C14.9407 3.39352 15.0731 3.59165 15.1634 3.80972C15.2537 4.02778 15.3002 4.26149 15.3002 4.49752C15.3002 4.73355 15.2537 4.96727 15.1634 5.18533C15.0731 5.40339 14.9407 5.60153 14.7738 5.76843L6.19518 14.347L2.7002 15.3002L3.65337 11.8052L12.232 3.22662Z"
        stroke="#8C8E95"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default PencilIcon();
