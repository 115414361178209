import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/6ixlogo.png";

const HeaderLogo = () => {
  return (
    <React.Fragment>
      <div className="ai6ix-layout-header-logo">
        <Link title="6ix Ai" to="/">
          <img className="ai6ix-layout-header-logo-img" src={logo} alt="logo" />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default HeaderLogo;
