import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { useGlobalValue } from "../../GlobalContext";

const HeaderSidebar = () => {
  const { setOpenSidebar } = useGlobalValue();
  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    setActiveClass("active");
    return () => null;
  }, [isMobile]);

  return isMobile ? (
    <React.Fragment>
      <div className={`ai6ix-layout-sidebar  ${activeClass}`}>
        <div className="ai6ix-layout-sidebar-inner">
          <div className="ai6ix-layout-sidebar-body">
            <div className="ai6ix-layout-sidebar-header">
              Research Companies
            </div>
            <NavLink
              to="https://6ix.com/explore"
              className="ai6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Explore the Marketplace
            </NavLink>
            <NavLink
              to="https://ai.6ix.com"
              className="ai6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Chat with 6ix AI
            </NavLink>
            <NavLink
              to="https://6ix.com/request-meeting"
              className="ai6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Book 1:1 Meetings
            </NavLink>
            <div className="ai6ix-layout-sidebar-divider"></div>

            <div className="ai6ix-layout-sidebar-header">Attract Capital</div>

            <NavLink
              to="https://leads.6ix.com"
              className="ai6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Get Leads
            </NavLink>
            <NavLink
              to="https://6ix.com/create"
              className="ai6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Host Events
            </NavLink>
            <NavLink
              to="https://meetings.6ix.com"
              className="ai6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Schedule Meetings
            </NavLink>
            <div className="ai6ix-layout-sidebar-divider"></div>

            <div className="ai6ix-layout-sidebar-header">Share Expertise</div>
            <NavLink
              to="https://6ix.com/broadcast"
              className="ai6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Broadcast Interviews
            </NavLink>
            <div className="ai6ix-layout-sidebar-divider"></div>
            <NavLink
              to="https://6ix.com/support"
              className="ai6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Contact sales
            </NavLink>
            <NavLink
              to="https://6ix.com/demo"
              className="ai6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              View demo
            </NavLink>
          </div>
        </div>
      </div>

      <div
        className="ai6ix-layout-sidebar-overlay"
        onClick={() => setOpenSidebar(false)}
        role="link"
        tabIndex={-1}
      ></div>
    </React.Fragment>
  ) : null;
};

export default HeaderSidebar;
