import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { isMobile } from "react-device-detect";
import { useGlobalValue } from "../../GlobalContext";
import { MenuIcon, Plus, UserLogin } from "../../icons";
import LoginPopup from "../Account/Login/LoginPopup";
import LoginVerifyPopup from "../Account/Login/LoginVerifyPopup";
import { toast } from "react-toastify";
import HeaderAccounts from "./HeaderAccounts";
import HeaderAttract from "./HeaderAttract";
import HeaderExpertise from "./HeaderExpertise";
import HeaderInvest from "./HeaderInvest";
import HeaderTop from "./HeaderTop";
import HeaderUsers from "./HeaderUsers";
import HeaderLogo from "./HeaderLogo";
import { SESSION_AUTH } from "../../helper/auth";
import { USER_SERVICES } from "../../services";
import "./style.scss";
const Header = () => {
  const { isUserSession, UPDATE_USER_SESSION, LOGOUT_USER_SESSION } =
    SESSION_AUTH();
  const {
    isUserReinitialize,
    openSidebar,
    setOpenSidebar,
    loginRequest,
    setLoginRequest,
    loginUserVerify
  } = useGlobalValue();

  const [dataFetched, setDataFetched] = useState(false);
  const posthog = usePostHog();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      setDataFetched(false);
    }
    return () => null;
  }, [isUserSession]);

  useEffect(() => {
    if (isUserReinitialize) {
      setDataFetched(false);
    }
  }, [isUserReinitialize]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    USER_SERVICES.getUser({
      userId: userData?.userId,
      org: userData?.org?.id
    })
      .then(data => {
        if (data.code === 200) {
          UPDATE_USER_SESSION(data?.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch(error => {
        toast(error.message);
      });
  }, [dataFetched, userData, LOGOUT_USER_SESSION, UPDATE_USER_SESSION]);

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  useEffect(() => {
    if (userData) {
      posthog?.identify(userData.userId, {
        name: userData.name,
        email: userData.email
      });
    }
  }, [userData]);

  return (
    <React.Fragment>
      <div className="ai6ix-layout-header">
        <HeaderTop />
        <div className="ai6ix-layout-header-inner">
          {isMobile ? (
            <div className="ai6ix-layout-header-left">
              <div
                className="ai6ix-layout-header-menuButton"
                onClick={() => setOpenSidebar(!openSidebar)}
                role="button"
                tabIndex={-1}
              >
                <MenuIcon close={openSidebar} />
              </div>
              <div className="ai6ix-layout-header-lineV"></div>
              <HeaderLogo />
            </div>
          ) : (
            <div className="ai6ix-layout-header-left">
              <HeaderLogo />
              <HeaderInvest />
              <HeaderAttract />
              <HeaderExpertise />
            </div>
          )}

          <div className="ai6ix-layout-header-right">
            {userData?.org && (
              <React.Fragment>
                <div className="ai6ix-layout-header-right-item">
                  <HeaderAccounts />
                </div>
                <div className="ai6ix-layout-header-lineV"></div>

                <div className="ai6ix-layout-header-right-item">
                  <Link
                    to="https://6ix.com/start"
                    className="ai6ix-layout-header-right-item-btn"
                  >
                    <Plus circle={true} />
                    <div className="ai6ix-layout-header-right-item-btn-text">
                      NEW
                    </div>
                  </Link>
                </div>
              </React.Fragment>
            )}

            <div className="ai6ix-layout-header-right-item">
              {userData ? (
                <HeaderUsers />
              ) : (
                <div
                  className="ai6ix-layout-header-right-item-btn"
                  onClick={handleLogin}
                  role="button"
                  tabIndex={-1}
                >
                  <UserLogin />
                  <div className="ai6ix-layout-header-right-item-btn-text">
                    LOGIN
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {loginRequest && !userData ? (
        <LoginPopup dropOut={true} cancel={true} handleLogin={handleLogin} />
      ) : null}

      {loginUserVerify && !userData?.isVerify && (
        <LoginVerifyPopup cancel={true} />
      )}
    </React.Fragment>
  );
};

export default Header;
