export function SortIcon() {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3312 5.67858L5.99995 2.00146L2.6687 5.67858M2.6687 10.3195L5.99995 13.9966L9.3312 10.3195"
        stroke="#A1A1A1"
        strokeWidth="0.999759"
      />
    </svg>
  );
}
